import { defineStore } from "pinia";

export const useConfiguracionesStyles = defineStore({
  id: "configuracionesStyles",
  state: () => ({
    configuracionesStyles: JSON.parse(
      localStorage.getItem("configuracionesStyles")
    ) || { staticMenuInactive: false },
  }),
  getters: {
    getConfiguracionesStyles: (state) => {
      return state.configuracionesStyles;
    },
  },
  actions: {
    setStaticMenuInactive(state) {
      let staticMenuInactive = state.staticMenuInactive;

      if (staticMenuInactive) {
        localStorage.setItem(
          "configuracionesStyles",
          JSON.stringify({ staticMenuInactive: true })
        );
      } else {
        localStorage.setItem(
          "configuracionesStyles",
          JSON.stringify({ staticMenuInactive: false })
        );
        state.configuracionesStyles = {
          staticMenuInactive: staticMenuInactive,
        };
      }
      return;
    },
  },
});
